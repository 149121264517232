const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: "/tools/:identifier",
    exact: true,
    component: "Tools",  // Assuming you have a component named "Tools"
  },
  {
    path: "/purchase/success",
    exact: true,
    component: "PurchaseSuccess",  // You will need to create this component
  },
];

export default routes;
